import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  html {
    scroll-behavior: smooth;
  }

  // Content only shown for screen readers
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  strong::before, strong::after {
    content: " ";
  }
  @media print {
    .main-navigation, .main-footer {
      display: none;
    }
  }

  /* hey08 Styles */
  .hey08Variation {
    display: none;
  }

`;
