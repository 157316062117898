import { useEffect } from 'react';
import { parse } from 'qs';

import { enforceExperimentForTesting, EXPERIMENT } from 'lib/ablyft';

/**
 * Infers the experiment from the url, and sets it in the sessionStorage for testing purposes.
 * This way the experiment can be inferred by `isVariationActive` calls  during the session
 *
 * @return {void}
 */
export function useExperimentFromQueryParam() {
  useEffect(() => {
    const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
    if (isProduction) {
      return;
    }

    const { ablyft_set_bucketing } = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!ablyft_set_bucketing) {
      return;
    }

    let parsedValue = null;

    try {
      parsedValue = JSON.parse(ablyft_set_bucketing);
    } catch {
      console.error('invalid ablyft_set_bucketing parameter');
    }

    if (Array.isArray(parsedValue)) {
      const [experimentID, variationID] = parsedValue[0].split('_');

      const [experimentName, experiment] = Object.entries(EXPERIMENT).find(
        ([_experimentName, experiment]) => {
          if (experiment.id === Number(experimentID)) {
            return true;
          }
        }
      );

      if (!experiment) {
        return;
      }

      const [variationName, variation] = Object.entries(
        experiment.variations
      ).find(([_variationName, variation]) => {
        if (variation.id === Number(variationID)) {
          return true;
        }
      });
      if (!variation) {
        return;
      }

      const foundVariation =
        EXPERIMENT[experimentName].variations[variationName];
      enforceExperimentForTesting(foundVariation, () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('ablyft_set_bucketing');
        window.location.href = url.toString();
      });
    }
  }, []);
}
