import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LOCALES } from 'shared/constants';

const GERMAN_RESOURCES = {
  common: require('../public/artifacts/locales/de/common.json'),
  static_pages: require('../public/artifacts/locales/de/static_pages.json'),
  dynamic_landing_page: require('../public/artifacts/locales/de/dynamic_landing_page.json'),
  hub: require('../public/artifacts/locales/de/hub.json'),
  qualification: require('../public/artifacts/locales/de/qualification.json'),
  caterers: require('../public/artifacts/locales/de/caterers.json'),
  sommerparty: require('../public/artifacts/locales/de/sommerparty.json'),
  events: require('../public/artifacts/locales/de/events.json'),
  food_program: require('../public/artifacts/locales/de/food_program.json'),
  about_us: require('../public/artifacts/locales/de/about_us.json'),
  homepage: require('../public/artifacts/locales/de/homepage.json'),
};

const ENGLISH_RESOURCES = {
  common: require('../public/artifacts/locales/en/common.json'),
  static_pages: require('../public/artifacts/locales/en/static_pages.json'),
  dynamic_landing_page: require('../public/artifacts/locales/en/dynamic_landing_page.json'),
  hub: require('../public/artifacts/locales/en/hub.json'),
  qualification: require('../public/artifacts/locales/en/qualification.json'),
  caterers: require('../public/artifacts/locales/en/caterers.json'),
  sommerparty: require('../public/artifacts/locales/en/sommerparty.json'),
  events: require('../public/artifacts/locales/en/events.json'),
  food_program: require('../public/artifacts/locales/en/food_program.json'),
  about_us: require('../public/artifacts/locales/en/about_us.json'),
  homepage: require('../public/artifacts/locales/en/homepage.json'),
};

export const localeResources = {
  en: {
    ...ENGLISH_RESOURCES,
  },
  de: {
    ...GERMAN_RESOURCES,
  },
  da: {
    ...ENGLISH_RESOURCES,
    static_pages: require('../public/artifacts/locales/da/static_pages.json'),
  },
};

export const LOCALE_FALLBACKS = {
  'en-uk': ['en'],
  'en-de': ['en'],
  'de-at': ['de'],
  'de-ch': ['de'],
  'de-de': ['de'],
  'da-dk': ['da'],
};

export const getFallbackForLocale = (locale) => {
  const fallback = LOCALE_FALLBACKS[locale];
  if (fallback) return fallback[0];
  return locale;
};

export const i18nextInit = (router) => {
  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      lng: router.locale,
      supportedLngs: router.locales,
      preload: LOCALES,
      fallbackLng: LOCALE_FALLBACKS,
      resources: localeResources,
      interpolation: {
        escapeValue: false,
      },
      initImmediate: false,
      react: {
        useSuspense: false,
      },
    });
  }

  if (i18next.language !== router.locale) {
    i18next.changeLanguage(router.locale);
  }

  return i18next;
};
