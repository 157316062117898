type Options = {
  reloadPage?: boolean;
};

/**
 * When introducing new experiments, it's variant name should be added here
 */
const AB_VARIANTS = [] as const;

export type ABVariant = typeof AB_VARIANTS[number];

function validateVariant(value: unknown) {
  if (typeof value === 'string' && AB_VARIANTS.includes(value as ABVariant)) {
    return;
  }
  throw new Error('invalid variant');
}

export const isABVariantActive = (variant: ABVariant) => {
  validateVariant(variant);
  if (typeof window === 'undefined') {
    return false;
  }
  return !!sessionStorage.getItem(`variant__${variant}`);
};

export const enableABVariant = (
  variant: ABVariant,
  { reloadPage }: Options = {}
) => {
  if (typeof window === 'undefined') {
    return;
  }
  validateVariant(variant);
  sessionStorage.setItem(`variant__${variant}`, 'true');
  if (reloadPage) {
    location.reload();
  }
};

export const disableABVariant = (
  variant: ABVariant,
  { reloadPage }: Options = {}
) => {
  if (typeof window === 'undefined') {
    return;
  }
  validateVariant(variant);
  sessionStorage.removeItem(`variant__${variant}`);
  if (reloadPage) {
    location.reload();
  }
};

export const toggleABVariant = (variant: ABVariant, options: Options = {}) => {
  if (typeof window === 'undefined') {
    return;
  }
  validateVariant(variant);
  if (isABVariantActive(variant)) {
    disableABVariant(variant, options);
  } else {
    enableABVariant(variant, options);
  }
};
