import { Ratings } from 'shared/services/trustedShops/model';

const URL =
  'http://integrations.etrusted.com/feeds/grades/v1/channels/chl-735bd2eb-de5a-4bf6-8510-55080217cff2/touchpoints/all/feed.json';

export function trustedShopsScript() {
  if (
    typeof customElements === 'undefined' ||
    customElements?.get('etrusted-widget') ||
    window.isEtrustedScriptLoaded
  )
    return;
  window.isEtrustedScriptLoaded = true;

  const tag = document.createElement('script');
  tag.async = true;
  tag.src = 'https://integrations.etrusted.com/applications/widget.js/v2';
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(tag);
}

const RATINGS_CACHE_KEY = 'trustedshops_ratings';

export const fetchRatings = async (): Promise<Ratings> => {
  const cache = (await import('./fetchCache')).default;
  const cachedData = cache.get<Ratings>(RATINGS_CACHE_KEY);

  if (cachedData !== undefined) {
    const isValid = '365days' in cachedData;
    if (isValid) {
      return cachedData;
    }
  }

  const res = await fetch(URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const json = (await res.json()) as { grades: Ratings };

  const ratings = json.grades;

  if (ratings) {
    cache.set(RATINGS_CACHE_KEY, ratings);
  }

  return ratings;
};
