import React from 'react';
import ErrorPage from 'next/error';
import PropTypes from 'prop-types';

import Bugsnag from 'lib/bugsnag';

function Error({ statusCode = 500 }) {
  return <ErrorPage statusCode={statusCode} />;
}

Error.getInitialProps = ({ res, err }) => {
  if (err) Bugsnag.notify(err);

  return { statusCode: res.statusCode };
};

Error.propTypes = {
  statusCode: PropTypes.number,
};

export default Error;
