import React, { createContext, ReactNode, useState } from 'react';
import { GDPR_COOKIE_CONSENT_NAME } from '@heycater/cookie-bar';
import Cookies from 'js-cookie';

import CookieBar from 'shared/components/CookieBar';

interface CookieContextType {
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConsentGiven: React.Dispatch<React.SetStateAction<boolean>>;
  consentGiven: boolean;
}

interface CookieContextProviderProps {
  children: ReactNode;
}

export const CookieContext = createContext<CookieContextType | undefined>(
  undefined
);

export default function CookieContextProvider({
  children,
}: CookieContextProviderProps) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [consentGiven, setConsentGiven] = useState(
    () => Cookies.get(GDPR_COOKIE_CONSENT_NAME) === 'true'
  );

  return (
    <CookieContext.Provider
      value={{ detailsOpen, setDetailsOpen, setConsentGiven, consentGiven }}
    >
      {children}
      <CookieBar />
    </CookieContext.Provider>
  );
}
