import { INDEX_LOCALES } from 'shared/constants';
import { createCanonicalURL } from 'shared/helpers/urls';

export function setRobotsDirective(pageProps, locale) {
  const isLocaleValid = INDEX_LOCALES.includes(locale);
  if (!isLocaleValid) {
    return 'noindex';
  }

  const pageHasEnglishVersion = doesPageHaveEnglishVersion(pageProps);

  if (locale === 'en' && !pageHasEnglishVersion) {
    return 'noindex';
  }

  const robotsDirective =
    pageProps.robots || pageProps.page?.robots || pageProps.post?.robots;

  if (robotsDirective) {
    return robotsDirective;
  }

  return 'index, follow';
}

export function getAlternateTags({ pageProps, router }) {
  const pageHasEnglishVersion = doesPageHaveEnglishVersion(pageProps);
  const alternateTags = [
    { hrefLang: 'x-default', href: createCanonicalURL(router, 'de') },
  ];

  if (pageHasEnglishVersion) {
    INDEX_LOCALES.forEach((indexLocale) =>
      alternateTags.push({
        hrefLang: indexLocale,
        href: createCanonicalURL(router, indexLocale),
      })
    );
  }

  if (!pageHasEnglishVersion) {
    if (router.locale === 'en') {
      return [];
    }

    if (router.locale === 'de') {
      alternateTags.push({
        hrefLang: router.locale,
        href: createCanonicalURL(router, router.locale),
      });
    }
  }

  return alternateTags;
}

export function getCanonicalTagHref({ pageProps, router }) {
  const pageHasEnglishVersion = doesPageHaveEnglishVersion(pageProps);

  if (router.locale === 'en' && !pageHasEnglishVersion) {
    return null;
  }

  return createCanonicalURL(router);
}

function doesPageHaveEnglishVersion(pageProps) {
  return Boolean(
    pageProps?.hasEnglishVersion ||
      pageProps?.page?.hasEnglishVersion ||
      pageProps?.post?.hasEnglishVersion
  );
}
