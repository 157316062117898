import { theme } from '@heycater/design-system';
import deepMerge from 'deepmerge';

const extendedTheme = {
  colors: {
    periglacialBlue: '#DDE3D3',
    beige: '#F1F5D8',
    heroGradient:
      'radial-gradient(64.09% 64.09% at 63.96% 44.52%, #e2ec97 7.76%, #e7f4c3 79.45%)',
  },
  palette: {
    text: {
      faded: '#3C4340',
    },
    secondary: {
      400: '#7d8f5c',
      450: '#007F3B',
      500: '#007f33',
    },
    grey: {
      250: '#e3e4e4',
      secondary: '#686D6B',
    },
  },
  navbar: {
    height: '65px',
  },
} as const;

const appTheme = deepMerge(theme, extendedTheme);

export type AppTheme = typeof appTheme;

export default appTheme;
