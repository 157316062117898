import {
  disableABVariant,
  enableABVariant,
  toggleABVariant,
} from 'shared/helpers/handleABVariants';

if (typeof window !== 'undefined') {
  window.ab = {
    enableVariant: enableABVariant,
    disableVariant: disableABVariant,
    toggleVariant: toggleABVariant,
  };
}
