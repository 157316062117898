import Bugsnag from '@bugsnag/js';
import { cacheExchange, Client, fetchExchange, mapExchange } from 'urql';

export const createURQLClient = (endpoint) =>
  new Client({
    url: endpoint,
    exchanges: [
      cacheExchange,
      mapExchange({
        onError,
      }),
      fetchExchange,
    ],
    fetch: fetcher,
  });

function onError(error, operation) {
  if (error.networkError) {
    Bugsnag.leaveBreadcrumb('URQL::onError::networkError', {
      networkError: error,
      operation,
    });
  }

  if (error.graphQLErrors) {
    error.graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      Bugsnag.leaveBreadcrumb('URQL::onError::graphqlError', {
        path,
        operation,
        extensions,
        message: message,
        locations: JSON.stringify(locations),
      });
    });
  }
}

function fetcher(url, options) {
  const { operationName } = JSON.parse(options.body);
  const token = localStorage.getItem('token');
  options.headers['Content-Type'] = 'application/json';
  if (token) {
    options.headers.Authorization = `${token}`;
  }
  return fetch(
    `${url}${operationName ? `?${operationName.toLowerCase()}` : ''}`,
    options
  );
}
