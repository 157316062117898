export const SALESFORCE_LEAD_RECORD_TYPES_MAPPING = {
  'Customer Lead': '01258000000DWRx',
  'Direct Order': '0121i000000YCDT',
  'heykantine Lead': '0121i000000YCDO',
  heykitchen: '0121i000000YCKK',
  'Hunter Process': '0121i000000HJ4Z',
  'Partner Lead': '01258000000DWRs',
  'Virtual Event': '0121i000000PkvU',
};

export const LINKS = {
  career: 'https://careers.smartrecruiters.com/heycater/',
};

export const SALESFORCE_SATIATION_MAPPING = {
  extra_filling_meal: 'Extra filling meal',
  filling_meal: 'Filling meal',
  snacks: 'Snack',
  appetizer: 'Appetizer <350g',
};

export const PARTNER_PAGE_ID = '6vp9wG5qefcG5XxvkSdqAj';

export const CATERER_APPLICATION_URL =
  'https://bookingheycater.typeform.com/to/EjUKlGDU';

export const LOCALES = [
  'da-dk',
  'en-uk',
  'de-at',
  'de-ch',
  'de-de',
  'en-de',
  'en',
  'de',
  'da',
] as const;

export type NextLocale = typeof LOCALES[number];

export const INDEX_LOCALES = ['en', 'de'];

export const REDIRECT_LOCALE = 'redirectLocale';

export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const QUALIFICATION_POLICY =
  process.env.NEXT_PUBLIC_CODE_RED || 'marketplace';

export const DEFAULT_ANSWERS = {
  city: null,
  catering_categories: [],
  dietary_restrictions: [],
  degree_of_satiation: null,
  services: [],
  min_order_number: null,
  event_date: null,
  lead_type: null,
  budget: null,
  price_per_person: null,
  caterer_minimum_order_value: null,
};

export const MARKETPLACE_BANNED_CATERING_CATEGORIES = [
  'bbq',
  'drinks',
  'fine_dining',
  'christmas',
];

export const LEAD_TYPE = {
  BUSINESS: 'business',
  PRIVATE: 'private',
};

export const MIN_NUMBER_OF_OFFERS = 3;
export const PREDEFINED_OFFERS_MAX_PRICE_PER_PERSON = 35;
export const MIN_ORDER_NUMBER = 10;
export const MIN_BUDGET = 500;
export const PRICE_PER_PERSON = 7;

export const DELIVERY_COUNTRY_CODES = ['de'];

export const PHONE_COUNTRY_CODES = ['DE', 'AT', 'CH', 'US'];

export const QUALIFICATION_CITIES_KEYS = [
  'muenchen',
  'berlin',
  'frankfurt-am-main',
  'hamburg',
  'duesseldorf',
  'koeln',
  'stuttgart',
];

export const CONTACT_PHONE = {
  formatted: '+49 (0)30 568 37200',
  href: 'tel:+4903056837200',
};

export const BENEFITS = {
  CUSTOMER_COUNT: 2500,
  CATERER_COUNT: 578,
  CUSTOMER_SATISFACTION_PERCENT: '97,7',
  ORGANIZED_CATERINGS: 45000,
  INCREASE_IN_SALES_PERCENT: 80,
  EMPLOYEE_COUNT: 45,
  NATIONALITY_COUNT: 12,
};

export const PARTNER_LANDING_PAGE_SLUG = 'caterer';
export const CUSTOMER_LANDING_PAGE_SLUGS = ['business-catering'];
