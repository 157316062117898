import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const ENDPOINT_STORAGE_KEY = 'inferred_endpoint';
const ENDPOINT = process.env.NEXT_PUBLIC_PLATFORM_GRAPHQL_ENDPOINT;
const CAN_INFER_ENDPOINT_FROM_URL = !!process.env
  .NEXT_PUBLIC_CAN_INFER_GRAPHQL_ENDPOINT_FROM_URL;

if (!ENDPOINT) {
  throw new Error('Missing endpoint url');
}

export const useServerEndpoint = () => {
  const router = useRouter();
  const [serverEndpoint, setServerEndpoint] = useState(ENDPOINT);

  const serverUrlParam =
    typeof router?.query?.serverUrl === 'string'
      ? router.query.serverUrl
      : null;

  useEffect(() => {
    if (!CAN_INFER_ENDPOINT_FROM_URL) {
      sessionStorage.removeItem(ENDPOINT_STORAGE_KEY);
      return;
    }

    const storedServerEndpoint = sessionStorage.getItem(ENDPOINT_STORAGE_KEY);

    if (serverUrlParam && typeof serverUrlParam === 'string') {
      sessionStorage.setItem(ENDPOINT_STORAGE_KEY, serverUrlParam);
    }

    const endpointToUse = serverUrlParam || storedServerEndpoint || ENDPOINT;

    setServerEndpoint(endpointToUse);
  }, [serverUrlParam]);

  return serverEndpoint;
};
