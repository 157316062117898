import { ParsedUrlQueryInput } from 'querystring';

import { Router } from 'next/router';
import { parse } from 'qs';

import { isCypressTest } from 'shared/helpers/isCypressTest';

export const CUSTOMER_APP_HOST =
  process.env.NEXT_PUBLIC_CUSTOMER_APP_HOST || 'https://www.heycater.com';
export const marketplaceURL = (path = '/') => `${CUSTOMER_APP_HOST}${path}`;

export function createCanonicalURL(router: Router, localeRewrite = '') {
  const path = router.asPath.split('?')[0];
  const locale = localeRewrite ? localeRewrite : router.locale;

  return `${process.env.NEXT_PUBLIC_HOST}/${locale}${path === '/' ? '' : path}`;
}

// NOTE: Redirecting to another domain breaks cypress specs. Mocking window location its not possible.
// This should be retired once https://docs.cypress.io/api/commands/origin#Syntax its not longer experimental.
export const redirectToMarketplace = (url: string) => redirect(url);

export const redirectToCustomerAccountRequest = (path = '/') =>
  redirect(`${CUSTOMER_APP_HOST}${path}`);

const redirect = (url: string) => {
  if (isCypressTest()) {
    alert('Redirecting to marketplace');
    return;
  }
  window.location.href = url;
};

export const getQueryParams = () =>
  parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as ParsedUrlQueryInput;
